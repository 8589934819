


























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive<{
      invoiceEvent: any;
      search: any;
      vatType: any;
    }>({
      invoiceEvent: "",
      search: "",
      vatType: 5,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      dialog: false,
      invoiceEvents: [],
      invoiceEventId: "" as any,
    });

    //  Start fetch events from invoice program

    const fetchInvoiceEvents = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("/invoice/event", {
          params: { name: model.search || undefined, id: state.invoiceEventId },
        })
        .then(({ data: { event } }) => {
          state.invoiceEvents = event
            ? event.map((e: any) => ({ ...e, kon_id: `${e.kon_id}` }))
            : [];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.invoiceEvents = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    //  End fetch events from invoice program

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        invoiceEventId: model.invoiceEvent,
        vatType: model.vatType,
      };

      state.loading = true;

      axiosInstance
        .put(`invoice/event/${root.$route.params.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.invoiceEventId = event.invoiceEventId;
          model.invoiceEvent = event.invoiceEventId;
          model.vatType = event.vatType;
          fetchInvoiceEvents();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    watch(
      () => model.search,
      () => {
        state.invoiceEventId = undefined;
        fetchInvoiceEvents();
      }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.objectExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
    };
  },
});
